<template>
  <div class="contractSign">
    <div class="box">
      <div class="boxText">
        <canvas v-for="page in pdf_pages" :id="'the_canvas' + page" :key="page"></canvas>
      </div>
      <div class="download" @click="download">下载</div>
      <div class="base" v-if="!$route.query.type">
        <div class="left">
          <div class="list">
            <span>甲方：</span>
            <div class="img-content">
              <!-- <img class="img" :src="stampUrl" /> -->
            </div>
          </div>
          <div class="list">
            <span>日期：</span>
            <div class="content">{{ formData.contract_start }}</div>
          </div>
        </div>
        <div class="right">
          <div class="list">
            <span>乙方：</span>
            <div class="content" @click="sign">
              <span class="sign">点击签字</span>
            </div>
          </div>
          <div class="list">
            <span>日期：</span>
            <div class="content">{{ formData.nowTime }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <van-popup v-model:show="show" round :closeable="true"> -->
    <div class="sign-mask" v-if="show">
      <div class="modelBox">
        <div class="base">
          <div class="btn">
            <van-button color="#85C226" plain round @click="clearcanvas">重写</van-button>
          </div>
          <div class="btn">
            <van-button type="primary" round @click="confirm">确定</van-button>
          </div>
        </div>

        <div class="drawArea" ref="drawAreaBox">
          <canvas class="canvasBox" id="myCanvas" ref="drawArea" @touchstart="mStart" @touchmove="mMove"
            @touchend="mEnd"></canvas>
        </div>
        <!-- <div class="title" @click="show=false">
					x
				</div> -->
      </div>
      <div class="close" @click="show = false">
        ×
        <!-- <img src="../../assets/image/del.png" alt=""> -->
      </div>
    </div>

    <van-popup v-model:show="loading">
      <div class="loading">
        <img src="../../assets/image/loading.gif" />
      </div>
    </van-popup>
  </div>
</template>

<script>
let PDFJS = require('pdfjs-dist');
PDFJS.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry.js');

export default {
  name: 'contractSign',
  data () {
    return {
      downloadshow: false,
      show: false,
      loading: true,
      stageInfo: null,
      ctx: null,
      moving: false,
      point: {
        x: 0,
        y: 0
      },
      id: '',
      detail: '',
      propertyUserInfo: '',
      pdf_scale: 1, //pdf放大系数
      pdf_pages: [],
      pdf_div_width: '',
      pdf_src: null,
      stampUrl: '',
      formData: {},
      isIos: false
    };
  },
  created () {
    this.isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    this.id = this.$route.query.id;
    this.getDetail();
    let market = window.localStorage.getItem('propertyUserInfo') || '';
    if (market) {
      this.propertyUserInfo = JSON.parse(market);
    }
  },
  mounted () { },
  methods: {
    getstamp (id) {
      this.$require
        .HttpPost('/h5/setting/contractNumberSetting', {
          property_tpl_id: id
        })
        .then((res) => {
          try {
            this.stampUrl = JSON.parse(res.data.values).electronicSeal;
          } catch (error) {
            console.log('JSON解析错误');
          }
        });
    },
    download () {
      this.$router.push({
        path: '/account/downloadPDF',
        query: {
          src: this.pdf_src
        }
      });
    },
    _loadFile (url) {
      //初始化pdf

      let loadingTask = PDFJS.getDocument(url);
      loadingTask.promise.then((pdf) => {
        this.pdfDoc = pdf;
        this.pdf_pages = this.pdfDoc.numPages;
        //debugger
        this.$nextTick(() => {
          this._renderPage(1);
        });
      });
    },
    _renderPage (num) {
      //渲染pdf页
      const that = this;
      this.pdfDoc.getPage(num).then((page) => {
        let canvas = document.getElementById(`the_canvas${num}`);
        let ctx = canvas.getContext('2d');
        let dpr = window.devicePixelRatio || 1;
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;
        let ratio = dpr / bsr;
        let viewport = page.getViewport({
          scale: this.pdf_scale
        });

        canvas.width = viewport.width * ratio;
        canvas.height = viewport.height * ratio;

        canvas.style.width = `${viewport.width}px`;

        that.pdf_div_width = `${viewport.width}px`;

        canvas.style.height = `${viewport.height}px`;

        ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
        let renderContext = {
          canvasContext: ctx,
          viewport
        };
        page.render(renderContext);
        if (this.pdf_pages > num) {
          this._renderPage(num + 1);
        } else {
          this.loading = false;
        }
      });
    },
    // 获取信息
    getDetail () {
      this.$require
        .HttpPost('/h5/contract/contract', {
          contract_id: this.id
        })
        .then((res) => {
          if (res.data) {
            if (res.data.contract.contract_rent_info) {
              res.data.contract.contract_rent_info = JSON.parse(
                res.data.contract.contract_rent_info
              );
            }
            this.detail = res.data.contract;
            let isUrl = this.detail.contract_tpl.search(/https:\/\/|http:\/\//);
            if (isUrl == -1) {
              this.pdf_src = process.env.VUE_APP_IMG_URL + this.detail.contract_tpl;
            } else {
              this.pdf_src = this.detail.contract_tpl;
            }
            this.formData = res.data.contract;
            this.formData.nowTime = this.$common.getDate(new Date());

            this.getstamp(res.data.contract.property_tpl_id);
            this.$nextTick(() => {
              this._loadFile(this.pdf_src);
            });
          }
        });
    },
    mStart (e) {
      let x = e.touches[0].clientX - this.stageInfo.left;
      let y = e.touches[0].clientY - this.stageInfo.top; // 获取触摸点在画板（canvas）的坐标
      this.point.x = x;
      this.point.y = y;
      this.ctx.beginPath();
      this.moving = true;
    },
    mMove (e) {
      if (this.moving) {
        let x = e.touches[0].clientX - this.stageInfo.left;
        let y = e.touches[0].clientY - this.stageInfo.top; // 获取触摸点在画板（canvas）的坐标
        this.ctx.moveTo(this.point.x, this.point.y); // 把路径移动到画布中的指定点，不创建线条(起始点)
        this.ctx.lineTo(x, y); // 添加一个新点，然后创建从该点到画布中最后指定点的线条，不创建线条
        this.ctx.stroke(); // 绘制
        this.point.x = x;
        this.point.y = y; // 重置点坐标为上一个坐标
      }
    },
    mEnd () {
      if (this.moving) {
        this.ctx.closePath(); // 停止绘制
        this.moving = false; // 关闭绘制开关
      }
    },
    sign () {
      this.show = true;
      this.$nextTick(() => {
        let drawArea = document.getElementById('myCanvas'); // 获取DOM
        this.stageInfo = drawArea.getBoundingClientRect();
        drawArea.width = this.$refs.drawAreaBox.offsetWidth; // 设置画布宽
        drawArea.height = this.$refs.drawAreaBox.offsetHeight; // 设置画布高
        this.ctx = drawArea.getContext('2d'); // 二维绘图
        this.ctx.strokeStyle = 'black'; // 颜色
        this.ctx.lineWidth = '3'; // 线条宽度
      });
      this.$nextTick(() => {
        document.querySelector('.sign-mask').addEventListener(
          'touchmove',
          (e) => {
            e.preventDefault();
          },
          { passive: false }
        );
      });
    },
    getImgData () {
      let Width = this.$refs.drawAreaBox.offsetWidth;
      let Hieght = this.$refs.drawAreaBox.offsetHeight;

      const imageData = this.ctx.getImageData(0, 0, Width, Hieght);
      const data = imageData.data;
      let minX = Width;
      let minY = Hieght;
      let maxX = -1;
      let maxY = -1;

      for (let y = 0; y < Hieght; y++) {
        for (let x = 0; x < Width; x++) {
          const pixelIndex = (y * Width + x) * 4;
          const alpha = data[pixelIndex + 3];

          if (alpha > 0) {
            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
          }
        }
      }

      return { minX, minY, maxX, maxY };
    },
    confirm () {
      this.loading = true;
      let img = ''
      const { minX, minY, maxX, maxY } = this.getImgData();
      const signatureWidth = maxX - minX;
      const signatureHeight = maxY - minY;

      if (signatureWidth > 0 && signatureHeight > 0) {
        const signatureCanvas = document.createElement('canvas');
        const signatureCtx = signatureCanvas.getContext('2d');

        signatureCanvas.width = signatureWidth;
        signatureCanvas.height = signatureHeight;

        const signatureImageData = this.ctx.getImageData(minX, minY, signatureWidth, signatureHeight);
        signatureCtx.putImageData(signatureImageData, 0, 0);

        // 将Canvas转换为base64编码
        const base64Data = signatureCanvas.toDataURL();
        img = base64Data;
      } else {
        this.$toast('请签名');
        return;
      }
      let form = {
        merchants_address: this.$route.query.merchants_address,
        merchants_phone: this.$route.query.merchants_phone,
        merchants_email: this.$route.query.merchants_email,
        contract_id: this.id,
        contract_address: img,
        contract_signal: this.isIos ? 1 : 2
      };

      if (this.isCanvasBlank(document.getElementById('myCanvas'))) {
        return;
      }

      this.$require.HttpPost('/h5/contract/writeName', form).then(() => {
        this.$toast('签名成功');
        this.loading = false;
        setTimeout(() => {
          this.$router.replace({
            path: '/my'
          });
        }, 500);
      }).catch(() => {
        this.loading = false;
      });
    },
    clearcanvas () {
      this.$nextTick(() => {
        let c = document.getElementById('myCanvas');
        let cxt = c.getContext('2d');
        this.ctx.clearRect(
          0,
          0,
          this.$refs.drawAreaBox.offsetWidth,
          this.$refs.drawAreaBox.offsetHeight
        );
      });
    },
    //验证canvas画布是否为空
    isCanvasBlank (canvas) {
      let blank = document.createElement('canvas'); //系统获取一个空canvas对象
      blank.width = canvas.width;
      blank.height = canvas.height;
      return canvas.toDataURL() == blank.toDataURL(); //比较值相等则为空
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .van-popup {
  background-color: transparent;
}

.open {
  font-size: 28px;
  color: #ffffff;
}

.contractSign {
  background: #b9f55c;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 30px 24px 40px 24px;
  overflow: hidden;

  .box {
    background: #ffffff;
    height: 100%;
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    padding: 0 20px;
    position: relative;
    overflow: hidden;

    .title {
      padding: 30px 0;
      text-align: center;
      font-size: 32px;
      color: #000000;
    }

    .head {
      .list {
        display: flex;
        font-size: 28px;
        color: #333333;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .boxText {
      position: relative;
      overflow-y: auto;
      margin-top: 40px;
      font-size: 28px;
      color: #010000;
      height: calc(100vh - 450px);
    }

    .download {
      width: 100px;
      padding: 10px;
      line-height: 40px;
      text-align: center;
      font-size: 28px;
      background-color: #ffffff;
      border: 1px solid #eeeeee;
      margin-left: auto;
    }

    .base {
      height: 140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 0px;
      width: calc(100% - 40px);
      bottom: 0;
      font-size: 28px;
      padding: 0 20px;
      color: #333333;

      .left {
        .img {
          width: 200px;
          position: absolute;
          bottom: 100px;
        }
      }

      .list {
        display: flex;
        margin-bottom: 20px;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        .sign {
          color: #529100;
        }
      }
    }
  }

  .sign-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    // align-items: center;
    background: rgba(0, 0, 0, 0.7);

    .close {
      position: absolute;
      bottom: 20px;
      right: 10px;
      color: #ffffff;
      font-size: 80px;

      img {
        width: 60px;
        height: 60px;
      }
    }

    .modelBox {
      width: 85%;
      height: 85%;
      background: #ffffff;
      border-radius: 30px;
      margin: auto;
      display: flex;

      .title {
        // width: 60px;
        height: 100%;
        display: flex;

        span {
          display: block;
          // 	width:350px;
          // height: 60px;

          font-size: 32px;
          color: #000000;
          transform: rotate(90deg);
          margin: auto;
          border: 1px solid;
        }
      }

      .drawArea {
        width: 80%;
        height: 100%;
        background: #f3f9e9;
        border-radius: 15px;
        margin: 0 auto;
        flex: 1;

        .canvasBox {
          width: 100%;
          height: 100%;
        }
      }

      .base {
        width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;

        .btn {
          width: 250px;
          height: 96px;
          transform: rotate(90deg);

          &:last-child {
            margin-top: 250px;
          }
        }
      }
    }
  }
}

.loading {
  width: 200px;

  img {
    width: 100%;
  }
}
</style>
